import { type Toast } from '@providers/ToastProvider';
import { AxiosError, isAxiosError } from 'axios';
import { FieldValues, UseFormSetError } from 'react-hook-form';

interface APIError {
  error: {
    message: string;
    messages:
      | {
          base: string[];
        }
      | Record<string, string[]>;
  };
}

//
// Sets form errors for react-hook-form
//
export const handleApiError = (
  error: AxiosError | Error,
  reactHookFormSetError: UseFormSetError<FieldValues>,
  toast: Toast | undefined,
) => {
  if (!isAxiosError(error)) {
    const message = error.message;

    if (toast) {
      return toast.error(message);
    }

    return reactHookFormSetError('root.serverError', {
      type: 'unknown',
      message,
    });
  }

  const data = error.response?.data as APIError;

  if (data?.error?.messages?.base) {
    const message = data.error.messages.base.join(' ');

    if (toast) {
      return toast.error(message);
    }

    reactHookFormSetError('root.serverError', {
      type: 'unknown',
      message,
    });
  } else if (data?.error?.messages) {
    Object.entries(data.error.messages).forEach(([name, message]) => {
      reactHookFormSetError(name, {
        type: 'manual',
        message: message.join(' '),
      });
    });
  } else {
    const message = error.message;

    if (toast) {
      return toast.error(message);
    }

    reactHookFormSetError('root.serverError', {
      type: 'unknown',
      message,
    });
  }
};

//
// Higher order function to allow passing react-hook-form's onError function
//
export const getHandleApiError =
  (reactHookFormSetError: UseFormSetError<FieldValues>, toast: Toast | undefined = undefined) =>
  (error: AxiosError | Error) =>
    handleApiError(error, reactHookFormSetError, toast);
