import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

import { tabHeight } from '@theme/MuiTabs';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.subtitle2,
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: theme.typography.body1.textTransform,
      minHeight: tabHeight,
    }),
  },
};
