import { fetcher } from '@utils/swr/fetcher';
import { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';

export function SWRProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <SWRConfig value={{ fetcher, shouldRetryOnError: false, revalidateOnFocus: false }}>
      {children}
    </SWRConfig>
  );
}
