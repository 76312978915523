import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  defaultProps: {
    size: 30,
    thickness: 7,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&::before': {
        content: '""',
        border: '5px solid',
        borderColor: theme.palette.grey[500],
        borderRadius: '50%',
        boxSizing: 'border-box',
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: -1,
      },
    }),
    circle: ({ theme }) => ({
      color: theme.palette.primary.main,
    }),
  },
};
