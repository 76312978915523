declare global {
  interface Window {
    __config__?: {
      PALETTE?: {
        primary: {
          main: string;
          light?: string;
          dark?: string;
        };
        secondary: {
          main: string;
          light?: string;
          dark?: string;
        };
        common: {
          navy: string;
        };
      };
    };
  }
}

function cloneDeepIfAvailable<T>(objectToClone: T): T {
  if (!objectToClone) {
    return {} as T;
  }
  return typeof structuredClone === 'function' ? structuredClone(objectToClone) : objectToClone;
}

// Deep Clone the config so people can't use the console to update and toggle configs
// eslint-disable-next-line no-underscore-dangle
export const CONFIG = cloneDeepIfAvailable(window.__config__);
