import { circularProgressClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import '@mui/lab/themeAugmentation';

export const MuiLoadingButton: Components<Theme>['MuiLoadingButton'] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    loadingIndicator: {
      [`& .${circularProgressClasses.root}::before`]: {
        border: '3px solid',
      },
    },
  },
};
