import { inputLabelClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/InputLabel' {
  export interface InputLabelClasses {
    sizeLarge: string;
  }
}

// custom size large requires new class
inputLabelClasses.sizeLarge = 'MuiInputLabel-sizeLarge';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    outlined: ({ theme }) => ({
      marginBottom: theme.spacing(3),
      color: theme.palette.text.primary,
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightRegular,
      transform: 'none',
      position: 'relative',
      [`&.${inputLabelClasses.sizeLarge}`]: {
        marginBottom: theme.spacing(5),
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
      },
    }),
  },
};
