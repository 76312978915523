import { alpha, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: `0px 2px 3px ${alpha(theme.palette.grey[900], 0.14)}`,
    }),
  },
};
