import { createRoot } from 'react-dom/client';

export function mountApp(App: JSX.Element) {
  const container = document.getElementById('vite-container');
  if (container) {
    const root = createRoot(container);
    root.render(App);
  } else {
    throw new Error(
      'Element not found: "vite-container". Ensure <div id="vite-container"></div> is in the DOM.',
    );
  }
}
