import createPalette from '@mui/material/styles/createPalette';

import { CONFIG } from '@constants/config';

declare module '@mui/material/styles/createPalette' {
  export interface CommonColors {
    gray: string;
    silver: string;
    navy: string;
  }

  export interface PaletteOptions {
    powder: import('@mui/material/styles/createPalette').PaletteColorOptions;
    slate: import('@mui/material/styles/createPalette').PaletteColorOptions;
  }

  export interface Palette {
    powder: import('@mui/material/styles/createPalette').PaletteColor;
    slate: import('@mui/material/styles/createPalette').PaletteColor;
  }
}

const primaryMain = CONFIG?.PALETTE?.primary.main || '#0970C5';
const secondaryMain = CONFIG?.PALETTE?.secondary.main || '#2EB6FF';
const navy = CONFIG?.PALETTE?.common.navy || '#074294';

export const palette = createPalette({
  text: {
    primary: '#323A41',
    disabled: '#5F707C',
  },
  common: {
    black: '#323A41',
    white: '#FFFFFF',
    gray: '#FAFBFC',
    silver: '#94B9D4',
    navy,
  },
  primary: {
    main: primaryMain,
    ...(CONFIG?.PALETTE?.primary.light && { light: CONFIG?.PALETTE?.primary.light }), // ?? '#0B99E6',
    ...(CONFIG?.PALETTE?.primary.dark && { dark: CONFIG?.PALETTE?.primary.dark }), // ?? '#0D61A6'
  },
  secondary: {
    main: secondaryMain,
    ...(CONFIG?.PALETTE?.secondary.light && { light: CONFIG?.PALETTE?.secondary.light }), // ?? '#66D1FF',
    ...(CONFIG?.PALETTE?.secondary.dark && { dark: CONFIG?.PALETTE?.secondary.dark }), // ?? '#1D9FE0',
    contrastText: '#fff',
  },
  grey: {
    50: '#F5FAFD',
    200: '#E7EFF5',
    400: '#D3E0E8',
    500: '#AEBFCC',
    700: '#7A8C99',
    900: '#485A66',
  },
  divider: '#D3E0E8', // grey[400]
  action: {
    disabled: '#7A8C99',
    disabledBackground: '#E7EFF5',
  },
  powder: {
    main: '#D9F1FF',
    light: '#F2FAFF',
    dark: '#AFDCFA',
  },
  success: {
    A100: '#EEFFFE',
    100: '#EDFFFD',
    300: '#7EE1D7',
    500: '#45C7BA',
    700: '#35B0A4',
    contrastText: '#FFF',
  },
  error: {
    200: '#FFF5F3',
    300: '#FAC5BB',
    400: '#F5897A',
    500: '#DF554D',
  },
  warning: {
    200: '#FFFFED',
    300: '#FAF0C0',
    400: '#F7E094',
    500: '#EED077',
  },
  slate: {
    100: '#F5F9FC',
  },
});
