import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCardHeader: Components<Theme>['MuiCardHeader'] = {
  defaultProps: {
    titleTypographyProps: {
      variant: 'h5',
      color: 'primary',
    },
    subheaderTypographyProps: {
      variant: 'body2',
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(10),
    }),
  },
};
