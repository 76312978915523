import { Alert, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FieldErrors } from 'react-hook-form-mui';

interface Props {
  errors?: FieldErrors | null;
  sx?: SxProps;
  message?: string | null;
}

export function ServerError({ errors = null, sx = {}, message = null }: Props) {
  const errorMessage = errors?.root?.serverError?.message || message;
  if (errorMessage) {
    return (
      <Alert sx={{ ...sx }} variant="outlined" severity="error">
        <Typography>{errorMessage}</Typography>
      </Alert>
    );
  }
  return <></>;
}
