import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTable: Components<Theme>['MuiTable'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& thead tr th, & tbody tr th': {
        ...theme.typography.body1,
        color: theme.palette.grey['700'],
        fontWeight: 800,
      },
    }),
  },
};
