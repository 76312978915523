import { createTheme } from '@mui/material';

import { MuiAccordion } from '@theme/MuiAccordion';
import { MuiAccordionDetails } from '@theme/MuiAccordionDetails';
import { MuiAccordionSummary } from '@theme/MuiAccordionSummary';
import { MuiAlert } from '@theme/MuiAlert';
import { MuiAppBar } from '@theme/MuiAppBar';
import { MuiButton } from '@theme/MuiButton';
import { MuiCard } from '@theme/MuiCard';
import { MuiCardContent } from '@theme/MuiCardContent';
import { MuiCardHeader } from '@theme/MuiCardHeader';
import { MuiCheckbox } from '@theme/MuiCheckbox';
import { MuiCircularProgress } from '@theme/MuiCircularProgress';
import { MuiDataGrid } from '@theme/MuiDataGrid';
import { MuiFormHelperText } from '@theme/MuiFormHelperText';
import { MuiInputAdornment } from '@theme/MuiInputAdornment';
import { MuiInputLabel } from '@theme/MuiInputLabel';
import { MuiLoadingButton } from '@theme/MuiLoadingButton';
import { MuiOutlinedInput } from '@theme/MuiOutlinedInput';
import { MuiStack } from '@theme/MuiStack';
import { MuiTab } from '@theme/MuiTab';
import { MuiTable } from '@theme/MuiTable';
import { MuiTableCell } from '@theme/MuiTableCell';
import { MuiTabs } from '@theme/MuiTabs';
import { MuiTextField } from '@theme/MuiTextField';
import { MuiTypography } from '@theme/MuiTypography';
import { palette } from '@theme/palette';
import { shadows } from '@theme/shadows';
import { shape } from '@theme/shape';
import { spacing } from '@theme/spacing';
import { typography } from '@theme/typography';

export const theme = createTheme({
  typography,
  shape,
  spacing,
  palette,
  shadows,
  components: {
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    MuiAlert,
    MuiAppBar,
    MuiButton,
    MuiCard,
    MuiCardContent,
    MuiCardHeader,
    MuiCheckbox,
    MuiCircularProgress,
    MuiDataGrid,
    MuiFormHelperText,
    MuiInputAdornment,
    MuiInputLabel,
    MuiLoadingButton,
    MuiOutlinedInput,
    MuiStack,
    MuiTab,
    MuiTabs,
    MuiTypography,
    MuiTable,
    MuiTableCell,
    MuiTextField,
  },
});
