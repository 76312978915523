import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(4, 6),
      ...theme.typography.subtitle2,
      borderBottomColor: theme.palette.divider,
    }),
  },
};
