import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/TextField' {
  export interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    margin: 'dense',
    fullWidth: false,
  },
};
