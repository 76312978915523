import { inputAdornmentClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/InputAdornment' {
  export interface InputAdornmentClasses {
    sizeLarge: string;
  }
}

// custom size large requires new class
inputAdornmentClasses.sizeLarge ??= 'MuiInputAdornment-sizeLarge';

export const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
  styleOverrides: {
    outlined: ({ theme }) => ({
      [`&.${inputAdornmentClasses.positionStart}.${inputAdornmentClasses.sizeLarge}`]: {
        svg: {
          ...theme.typography.h5,
        },
      },
    }),
  },
};
