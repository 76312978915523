export const swrMutationOptions = {
  onError: undefined,
  onSuccess: undefined,
  optimisticData: undefined,
  populateCache: true,
  // Starting with this off, populateCache would ideally handle this for us in most cases
  revalidate: false,
  rollbackOnError: true,
  throwOnError: true,
};
