import { buttonClasses, outlinedInputClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/InputBase' {
  export interface InputBasePropsSizeOverrides {
    large: true;
  }
}

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    notched: false,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[50],
      [`&.${outlinedInputClasses.disabled}`]: {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    }),
    input: ({ theme, ownerState }) => ({
      '::placeholder': {
        color: '#323a41',
      },
      padding: theme.spacing(4.97, 7), // odd value here to accommodate height of 1.4375em
      ...(ownerState.size === 'large' && {
        padding: theme.spacing(6.75, 7),
        ...theme.typography.h6,
      }),
      // https://github.com/mui/material-ui/blob/36523fa8232e5aaabf86dbfb682f5773d48a2321/packages/mui-material/src/OutlinedInput/OutlinedInput.js#L126C3-L134C6
      ...(ownerState.multiline && {
        padding: 0,
      }),
      ...(ownerState.startAdornment && {
        paddingLeft: 0,
      }),
      ...(ownerState.endAdornment && {
        paddingRight: 0,
      }),
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: theme.palette.grey[400],
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.disabled} > &`]: {
        borderColor: theme.palette.grey[500],
      },
    }),
    adornedEnd: {
      [`&:has(.${buttonClasses.root})`]: {
        paddingRight: 0,
      },
      [`.${buttonClasses.root}`]: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        whiteSpace: 'nowrap',
        minWidth: 'unset',
        boxShadow: 'none',
      },
    },
  },
};
