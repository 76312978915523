import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <ExpandMoreIcon />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(0, 10),
    }),
    content: ({ theme }) => ({
      margin: theme.spacing(9, 0),
      alignItems: 'center',
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontSize: '16px',
      lineHeight: '26px',
    }),
  },
};
