import { Typography } from '@mui/material/styles/createTypography';

function avenirVariation(fontSize: string, lineHeight: string, fontWeight: number) {
  return {
    fontFamily: 'Avenir, sans-serif',
    fontSize,
    fontWeight,
    lineHeight,
  };
}

export const typography = {
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightBold: 600,
  fontFamily: ['Avenir', 'sans-serif'].join(','),
  h1: avenirVariation('50px', '62px', 200),
  h2: avenirVariation('42px', '55px', 200),
  h3: avenirVariation('32px', '44px', 200),
  h4: avenirVariation('24px', '36px', 400),
  h5: avenirVariation('20px', '30px', 400),
  h6: avenirVariation('16px', '26px', 400),
  subtitle1: avenirVariation('14px', '22px', 400),
  subtitle2: avenirVariation('13px', '20px', 400),
  body1: avenirVariation('14px', '22px', 400),
  body2: avenirVariation('13px', '20px', 400),
  button: avenirVariation('14px', '22px', 800),
  caption: avenirVariation('12px', '18px', 400),
  overline: avenirVariation('10px', '15px', 400),
} as Typography;
