import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(10),
      '&:last-child': {
        padding: theme.spacing(10),
      },
    }),
  },
};
