import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    // for accessibility reasons we don't want our style variants to map to any heading element
    // instead use the component prop to specify
    variantMapping: {
      h1: 'div',
      h2: 'div',
      h3: 'div',
      h4: 'div',
      h5: 'div',
      h6: 'div',
      subtitle1: 'div',
      subtitle2: 'div',
      body1: 'p',
      body2: 'p',
      inherit: 'span',
    },
  },
};
