import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(10),
      paddingTop: 0,
    }),
  },
};
