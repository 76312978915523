import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const csrfToken = () =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ?? '';

const headers = {
  'X-CSRF-TOKEN': csrfToken(),
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const decamelizeReqestKeys = (data: object) => decamelizeKeys(data);
const camelizeResponseKeys = (data: object) => camelizeKeys(data);

const transformRequest = [
  decamelizeReqestKeys,
  ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
];
const transformResponse = (axios.defaults.transformResponse as AxiosResponseTransformer[]).concat([
  camelizeResponseKeys,
]);

export const httpService = axios.create({
  timeout: 30000,
  headers,
  transformRequest,
  transformResponse,
});
