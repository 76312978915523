import { buttonClasses, svgIconClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { PaletteColorOptions } from '@mui/material/styles/createPalette';

declare module '@mui/material/Button' {
  export interface PaletteOptions {
    powder: PaletteColorOptions;
  }
}

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 6,
      padding: theme.spacing(4.5, 7),
      textTransform: 'none',
      transition: 'none',
    }),
    contained: ({ theme, ownerState }) => ({
      ...(ownerState.color !== 'inherit' && {
        boxShadow: `inset 0 -2px 0 ${theme.palette[ownerState.color ?? 'primary']?.dark}`,
        '&:hover,&:focus': {
          color: theme.palette[ownerState.color ?? 'primary']?.contrastText, // needed to override bootstrap shenanigans
        },
        '&:hover': {
          backgroundColor: theme.palette[ownerState.color ?? 'primary']?.dark,
          boxShadow: `inset 0 -2px 0 ${theme.palette[ownerState.color ?? 'primary']?.dark}`,
        },
        [`&.${buttonClasses.focusVisible}`]: {
          backgroundColor: theme.palette[ownerState.color ?? 'primary']?.light,
          boxShadow: `inset 0 -2px 0 ${theme.palette[ownerState.color ?? 'primary']?.dark}`,
        },
        [`&.${buttonClasses.disabled}`]: {
          boxShadow: `inset 0 -2px 0 ${theme.palette.grey[400]}`,
        },
      }),
    }),
    outlined: ({ theme, ownerState }) => ({
      padding: theme.spacing(4, 7),
      borderColor: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightRegular,
      [`&.${buttonClasses.focusVisible}`]: {
        ...(ownerState.color !== 'inherit' && {
          borderColor: theme.palette[ownerState.color ?? 'primary']?.main,
        }),
      },
    }),
    sizeLarge: ({ theme }) => ({
      padding: theme.spacing(6, 8.5),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: '24px',
    }),
    outlinedSizeLarge: ({ theme }) => ({
      padding: theme.spacing(6.5, 8.5),
    }),
    sizeSmall: ({ theme, ownerState }) => ({
      ...theme.typography.body1,
      minWidth: 0,
      lineHeight: '20px', // move to typography
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(2.5, 5.5),
      ...(Boolean(ownerState.starIcon) && { paddingRight: theme.spacing(5.5) }),
    }),
    outlinedSizeSmall: ({ theme }) => ({
      padding: theme.spacing(2, 5.5),
    }),
    startIcon: ({ ownerState, theme }) => ({
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(4.5),
      ...(ownerState.size === 'small' && {
        marginRight: theme.spacing(3.5),
        [`.${svgIconClasses.root}`]: {
          fontSize: theme.typography.h6.fontSize,
        },
      }),
    }),
    endIcon: ({ ownerState, theme }) => ({
      ...(ownerState.size === 'small' && {
        marginRight: theme.spacing(3.5),
        [`.${svgIconClasses.root}`]: {
          fontSize: theme.typography.h6.fontSize,
        },
      }),
    }),
  },
};
