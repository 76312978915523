import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {
    variant: 'elevation',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...(ownerState.variant === 'outlined' && {
        borderColor: theme.palette.grey[400],
      }),
      ...(ownerState.variant === 'elevation' && {
        boxShadow: '0px 2px 3px rgba(72, 90, 102, 0.14);',
        border: '1px solid',
        borderColor: theme.palette.grey[400],
      }),
    }),
  },
};
