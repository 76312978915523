import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: {
      alignItems: 'center',
      padding: 16,
    },
    standardWarning: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.main,
    }),
    outlinedSuccess: ({ theme }) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor: (theme.palette.success as any)[100],
    }),
    outlinedError: ({ theme }) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor: (theme.palette.error as any)[200],
    }),
    outlinedWarning: ({ theme }) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor: (theme.palette.error as any)[200],
    }),
    outlinedInfo: ({ theme }) => ({
      backgroundColor: theme.palette.powder.light,
    }),
    message: ({ theme }) => ({
      ...theme.typography.subtitle2,
    }),
  },
};
