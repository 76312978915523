import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import '@mui/x-data-grid/themeAugmentation';
import { gridClasses } from '@mui/x-data-grid/constants/gridClasses';

export const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    rowHeight: 40,
    columnHeaderHeight: 37,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.secondary.dark,
      boxShadow: 'none',
    }),
    columnHeaders: ({ theme }) => ({
      backgroundColor: theme.palette.secondary.light,
    }),
    columnHeader: ({ theme }) => ({
      borderRight: '1px solid',
      borderColor: theme.palette.secondary.dark,
      '&:last-of-type': {
        borderRight: 0,
      },
    }),
    columnHeaderTitle: ({ theme }) => ({
      ...theme.typography.body1,
      fontWeight: 'bold',
    }),
    cellContent: ({ theme }) => ({
      ...theme.typography.subtitle2,
    }),
    row: {
      [`&:last-of-type .${gridClasses.cell}`]: {
        borderBottom: 0,
      },
    },
  },
};
