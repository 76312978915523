import { ThemeProvider } from '@mui/material';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { PropsWithChildren } from 'react';

import { theme } from '@theme/index';

export function MuiProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline sx={{ bgcolor: 'unset' }}>{children}</ScopedCssBaseline>
    </ThemeProvider>
  );
}
