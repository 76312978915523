import { alpha, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: `0px 2px 3px ${alpha(theme.palette.grey[900], 0.14)}`,
    }),
  },
};
