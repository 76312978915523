import type { SWRMutationConfiguration } from 'swr/mutation';
import { AxiosError } from 'axios';
import { swrMutationOptions } from '@config/swrMutationOptions';

type Data = object;

export interface OnSuccess {
  (data: Data, key: string, config: SWRMutationConfiguration<Data, Error>): void | undefined;
}

export interface OnError {
  (
    err: Error | AxiosError,
    key: string,
    config: SWRMutationConfiguration<Data, Error>,
  ): void | undefined;
}

export interface OptimisticData {
  (arg0: Data): Data;
}

export interface MutationOptions {
  onSuccess?: OnSuccess;
  onError?: OnError;
  optimisticData?: object;
  populateCache?: boolean;
  revalidate?: boolean;
  rollbackOnError?: boolean;
  throwOnError?: boolean | undefined;
}

export const getMutationOptions = (mutationOptions: MutationOptions = {}) => ({
  ...swrMutationOptions,
  throwOnError:
    typeof mutationOptions.throwOnError !== 'undefined'
      ? mutationOptions.throwOnError
      : typeof mutationOptions.onError !== 'function',
  ...mutationOptions,
});
