import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const tabHeight = 43;

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: {
      minHeight: tabHeight,
      height: tabHeight,
    },
  },
};
