import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: () => ({
      marginLeft: 0,
      marginRight: 0,
    }),
  },
};
